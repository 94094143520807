import { useFormikContext } from 'formik';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { toISO } from '@utils/date';

type Params = {
    name: string;
    onError?: () => void;
    defaultValue?: Date;
};

export const useDateInput = ({ name, defaultValue }: Params) => {
    const { getFieldProps, setFieldValue, setFieldError, errors } =
        useFormikContext<Record<string, string>>();
    const { locale } = useRouter();
    const [date, setDate] = useState<Date | null>(() => defaultValue ?? null);
    const [isSupported, setIsSupported] = useState<boolean>(true);

    const ref = useCallback((input: HTMLInputElement | null) => {
        if (input !== null) {
            setIsSupported(input.type === 'date');
        }
    }, []);

    const handleChange = (date: Date | null) => {
        setFieldValue(name!, date ? toISO(date) : undefined);
        setDate(date);
    };

    const setError = (error: string | undefined) => setFieldError(name, error);

    return {
        locale,
        isSupported,
        errors: errors[name],
        fieldProps: getFieldProps(name),
        value: date,
        handleChange,
        ref,
        setError,
    };
};
