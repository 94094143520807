import { ComponentProps, FC } from 'react';
import Icon from '@components/utils/icons/icon';
import styled from '@emotion/styled';

type CustomProps = {
    message: string;
};

export type FormErrorMessageProps = CustomProps & ComponentProps<'div'>;

const HStack = styled.div`
    color: red;
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing.xs};
    margin-top: ${({ theme }) => theme.spacing.xxs};
    transition: height 2s;
`;

export const FormErrorMessage: FC<FormErrorMessageProps> = ({ message }) => (
    <HStack>
        <Icon name='error' color='red' />
        {message}
    </HStack>
);
