import styled from '@emotion/styled';
import Icon from '@components/utils/icons/icon';
import { StyledProps } from '@emotion/react';
import { motion } from 'framer-motion';
import localeDirection from '@utils/locale/direction';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { COMMON } from '@constants';
import { useSurveyContext } from '../contexts/SurveyContext';
import StepIndicator from '@components/utils/stepIndicator';

const Container = styled.div`
    width: 100%;
    padding-block-end: ${({ theme }) => theme.spacing.md};
    padding-inline: ${(props) => props.theme.spacing.md};
    background-color: ${({ theme }) => theme.palette.background.default};
`;

const VStack = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xs};
`;

const StyledIcon = styled(Icon)<StyledProps<{ isHidden?: boolean }>>`
    visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
    z-index: 1;
    cursor: pointer;
`;

const HeaderContainer = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.spacing.sm};
    @media (min-width: 748px) {
        border-top-left-radius: ${({ theme }) => theme.shape.radius.default};
        border-top-right-radius: ${({ theme }) => theme.shape.radius.default};
    }
    gap: ${({ theme }) => theme.spacing.xs};
`;

const ProgressWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.palette.grey[200]};
    border-radius: 2px;
`;

const ProgressLine = styled(motion.div, {
    shouldForwardProp: (props) =>
        !['isRtl', 'currentStep', 'totalSteps'].includes(props),
})<
    StyledProps<{
        isRtl: boolean;
        currentStep: number;
        totalSteps: number;
    }>
>((props) => ({
    height: '2px',
    background: props.theme.palette.common.black,
    borderRadius: '2px',
    position: 'absolute',
    top: 0,
    right: props.isRtl ? 0 : 'auto',
    left: props.isRtl ? 'auto' : 0,
    width: `${((props.currentStep || 0 + 1) / (props.totalSteps || 1)) * 100}%`,
}));

const PageTitle = styled.h1`
    font: ${(props) => props.theme.fonts.body};
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
`;

const SurveyPageHeader: React.FC = () => {
    const isRtl = localeDirection(useRouter().locale) === 'rtl';
    const { t } = useTranslation(COMMON);

    const {
        survey,
        currentStep,
        numberOfPages,
        isInfoPage,
        goToPreviousSurveyPage,
    } = useSurveyContext();

    if (isInfoPage) {
        return null;
    }

    return (
        <Container>
            <VStack>
                <HeaderContainer>
                    <PageTitle>{survey?.Name || ''}</PageTitle>
                    <StyledIcon
                        name='keyboard_arrow_left'
                        size='medium'
                        onClick={goToPreviousSurveyPage}
                    />
                </HeaderContainer>
                <ProgressWrapper>
                    <ProgressLine
                        isRtl={isRtl}
                        currentStep={currentStep}
                        totalSteps={numberOfPages}
                        transition={{ duration: 0.3 }}
                    />
                </ProgressWrapper>
                <StepIndicator
                    text={t('step', {
                        current: currentStep,
                        total: numberOfPages,
                    })}
                    current={currentStep}
                    total={numberOfPages}
                />
            </VStack>
        </Container>
    );
};

export default SurveyPageHeader;
