import styled from '@emotion/styled';
import { InputFieldFragment } from '@graphql/generated/graphql';
import { Mandatory, ModalInput } from './textInput';
import { useFormikContext } from 'formik';
import { FormControl } from '@lib/uikit';

type Props = {
    element: InputFieldFragment;
};

const BoxInput = styled(ModalInput)`
    height: ${(props) => props.theme.spacing.md};
    width: ${(props) => props.theme.spacing.sm};
`;

const BoxContainer = styled.div`
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline-start: ${(props) => props.theme.spacing.xs};
`;

const BoxLabel = styled.p`
    padding-inline-start: ${(props) => props.theme.spacing.xs};
    font: ${(props) => props.theme.fonts.caption};
    text-align: left;
    padding-top: ${(props) => props.theme.spacing.xxs};
    padding-bottom: ${(props) => props.theme.spacing.xxs};
`;

const CheckBoxInput = ({ element }: Props) => {
    const { getFieldProps, errors } =
        useFormikContext<Record<string, string>>();

    return (
        <FormControl errorMessage={errors[element.Name]}>
            <BoxContainer>
                <BoxInput
                    type='checkbox'
                    required={element.IsRequired}
                    {...getFieldProps(`['${element.Name}']`)}
                />
                <BoxLabel>
                    {element.Placeholder}
                    <Mandatory>{element.IsRequired ? '*' : null}</Mandatory>
                </BoxLabel>
            </BoxContainer>
        </FormControl>
    );
};

export default CheckBoxInput;
