import { useMutation } from '@tanstack/react-query';
import {
    CustomerInput,
    CustomerInputType,
    CustomerRatingData,
} from '@hello-pimster/pimster-crm-sdk';
import { useApiCrm } from '@lib/pimster-crm/provider/ApiCrmProvider';
import { isCustomerRatingData } from '@lib/pimster-crm/types/guards';
import router from 'next/router';
import { useState } from 'react';

type PropsTypes = {
    storyTitle: CustomerRatingData['story_title'];
    locale: CustomerRatingData['locale'];
    productSlug: CustomerRatingData['product'];
    productUID: NonNullable<CustomerInput['product']>['cmsUID'];
};

export const useRating = ({
    storyTitle,
    locale,
    productSlug,
    productUID,
}: PropsTypes) => {
    const { client } = useApiCrm();
    const [rating, setRating] = useState<number | undefined>(undefined);
    const [entryId, setEntryId] = useState<number | undefined>(undefined);

    const { mutate: createRating } = useMutation({
        mutationFn: async (rating: number) => {
            if (!storyTitle || !router.locale) throw new Error('Invalid data');
            const res = await client.customerInputControllerCreate({
                customerInputCreate: {
                    type: CustomerInputType.Rating,
                    isProductRegistration: false,
                    product: { cmsUID: productUID },
                    data: {
                        _type: CustomerInputType.Rating,
                        submittedAt: new Date().toISOString(),
                        story_title: storyTitle,
                        rating: rating,
                        locale: locale,
                        product: productSlug,
                    },
                },
            });
            if (isCustomerRatingData(res.data)) return res.data;
            else throw new Error('Receives invalid response data');
        },
        onSuccess: (data) => {
            setEntryId(data.id);
            setRating(data.data.rating);
        },
        onError: (err) => {
            console.error(err);
            setRating(undefined);
        },
    });

    const { mutate: updateRating } = useMutation({
        mutationFn: async (rating: number) => {
            if (!entryId) throw new Error('Invalid data');
            const res = await client.customerInputControllerUpdate({
                id: entryId,
                customerInputUpdate: {
                    type: CustomerInputType.Rating,
                    data: {
                        _type: CustomerInputType.Rating,
                        rating: rating,
                    },
                },
            });
            if (isCustomerRatingData(res.data)) return res.data;
            else throw new Error('Receives invalid response data');
        },
        onSuccess: (data) => {
            setEntryId(data.id);
            setRating(data.data.rating);
        },
        onError: (err) => {
            console.error(err);
            setRating(undefined);
        },
    });

    const { mutate: deleteRating } = useMutation({
        mutationFn: async () => {
            if (!entryId) throw new Error('Invalid data');
            const res = await client.customerInputControllerDelete({
                id: entryId,
            });
            if (isCustomerRatingData(res.data)) return res.data;
            else throw new Error('Receives invalid response data');
        },
        onSuccess: () => {
            setEntryId(undefined);
            setRating(undefined);
        },
    });

    const handleRating = (newRate: number) => {
        if (newRate === rating) {
            deleteRating();
        } else {
            if (entryId) updateRating(newRate);
            else createRating(newRate);
        }
    };

    return { rating, submitRating: handleRating };
};
