import {
    CustomerInput,
    CustomerInputData,
    CustomerInputDataCustom,
    CustomerInputType,
    CustomerOnboardingData,
    CustomerRatingData,
    CustomerReviewData,
} from '@hello-pimster/pimster-crm-sdk';
import { isObject, isString } from 'lodash';
import { CUSTOMER_INPUT_TYPES } from '../constants';

export const isCustomerCustomData = (
    customerInput: CustomerInput
): customerInput is CustomerInput & { data: CustomerInputDataCustom } =>
    customerInput.type === CustomerInputType.Custom;

export const isCustomerReviewData = (
    customerInput: CustomerInput
): customerInput is CustomerInput & { data: CustomerReviewData } =>
    customerInput.type === CustomerInputType.Review;

export const isCustomerRatingData = (
    customerInput: CustomerInput
): customerInput is CustomerInput & { data: CustomerRatingData } =>
    customerInput.type === CustomerInputType.Rating;

export const isCustomerOnboardingData = (
    customerInput: CustomerInput
): customerInput is CustomerInput & { data: CustomerOnboardingData } =>
    customerInput.type === CustomerInputType.Onboarding;

export const isCustomerInputType = (
    value: unknown
): value is CustomerInputType => {
    return isString(value) && CUSTOMER_INPUT_TYPES.includes(value);
};

export const isCustomerInputData = (
    value: unknown
): value is CustomerInputData => {
    if (!isObject(value)) {
        return false;
    }

    if (!('_type' in value && isCustomerInputType(value._type))) {
        return false;
    }

    return true;
};

export const isCustomerInput = (value: unknown): value is CustomerInput => {
    if (!isObject(value)) {
        return false;
    }

    if (!('type' in value && isCustomerInputType(value.type))) {
        return false;
    }

    if (!('data' in value && isCustomerInputData(value.data))) {
        return false;
    }

    return true;
};
