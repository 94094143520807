import { useProductNavigation } from '@components/utils/contexts/ProductNavigationContext';
import { ModuleFragment } from '@graphql/generated/graphql';
import { TrackEvent } from '@enums';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { OrderedModule } from '@types';
import { useEffect, useRef, useState } from 'react';
import { useDebounce, useScroll } from 'react-use';

type Props = { reverse?: boolean; module?: OrderedModule | ModuleFragment };

export const useContentScroller = ({ reverse = false, module }: Props) => {
    const parentRef = useRef<HTMLUListElement>(null);
    const childrenRefs = useRef<HTMLLIElement[]>([]);
    const [allowForth, setAllowForth] = useState<boolean>(true);
    const [allowBack, setAllowBack] = useState<boolean>(false);
    const { track } = useAnalytics();
    const { maybeRestoreHorizontalNavigation } = useProductNavigation();

    useEffect(() => {
        if (module) {
            maybeRestoreHorizontalNavigation(parentRef, module);
        }

        return () => {
            childrenRefs.current = [];
        };
    }, [maybeRestoreHorizontalNavigation, module]);

    useDebounce(
        () => {
            if (reverse) {
                setAllowForth(
                    (parentRef.current &&
                        parentRef.current.scrollLeft >
                            1 -
                                parentRef.current.scrollWidth +
                                parentRef.current.clientWidth) ??
                        false
                );
                setAllowBack(
                    (parentRef.current && parentRef.current.scrollLeft < 0) ??
                        false
                );
            } else {
                setAllowForth(
                    (parentRef.current &&
                        parentRef.current.scrollLeft <
                            parentRef.current.scrollWidth -
                                parentRef.current.clientWidth -
                                1) ??
                        false
                );
                setAllowBack(
                    (parentRef.current && parentRef.current.scrollLeft > 0) ??
                        false
                );
            }
        },
        250,
        [useScroll(parentRef)]
    );

    const handleBack = () => {
        try {
            const targetEl = childrenRefs.current
                .filter(
                    (ref) =>
                        parentRef.current &&
                        (reverse
                            ? ref.getBoundingClientRect().right >
                              parentRef.current.getBoundingClientRect().right
                            : ref.getBoundingClientRect().left <
                              parentRef.current.getBoundingClientRect().left)
                )
                .slice(-1)[0];
            targetEl &&
                targetEl.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'end',
                });
            track(TrackEvent.ArrayNavigation, { direction: 'back' });
        } catch (e) {
            console.error(e);
        }
    };

    const handleForth = () => {
        try {
            childrenRefs.current.every((ref) => {
                if (
                    parentRef.current &&
                    (reverse
                        ? ref.getBoundingClientRect().left <
                          parentRef.current.getBoundingClientRect().left
                        : ref.getBoundingClientRect().right >
                          parentRef.current.getBoundingClientRect().right)
                ) {
                    ref.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start',
                    });
                    return false;
                }
                return true;
            });
            track(TrackEvent.ArrayNavigation, { direction: 'forth' });
        } catch (e) {
            console.error(e);
        }
    };

    return {
        parentRef,
        childrenRefs,
        allowBack,
        allowForth,
        handleBack,
        handleForth,
    };
};
