import { useState, useEffect } from 'react';
import styled from '@emotion/styled';

const RippleContainer = styled.div<{ duration: number }>`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    span {
        transform: scale(0);
        border-radius: 100%;
        position: absolute;
        opacity: 0.375;
        background-color: ${(props) => props.color};
        animation-name: ripple;
        animation-duration: ${(props) => props.duration}ms;
    }

    @keyframes ripple {
        to {
            opacity: 0;
            transform: scale(2);
        }
    }
`;

type UseDebounceRippleCleanUpProps = {
    rippleCount: number;
    duration: number;
    cleanUpFunction: () => void;
};

const useDebouncedRippleCleanUp = ({
    rippleCount,
    duration,
    cleanUpFunction,
}: UseDebounceRippleCleanUpProps) => {
    useEffect(() => {
        let bounce: number | undefined;

        if (rippleCount > 0) {
            if (bounce !== undefined) {
                clearTimeout(bounce);
            }

            bounce = window?.setTimeout(() => {
                cleanUpFunction();
                if (bounce !== undefined) {
                    clearTimeout(bounce);
                }
            }, duration * 4);
        }

        return () => {
            if (bounce !== undefined) {
                clearTimeout(bounce);
            }
        };
    }, [rippleCount, duration, cleanUpFunction]);
};

type RippleProps = {
    duration?: number;
    color?: string;
};

type RippleType = {
    x: number;
    y: number;
    size: number;
};

const Ripple = ({ duration = 850, color = '#ffffff86' }: RippleProps) => {
    const [rippleArray, setRippleArray] = useState<RippleType[]>([]);

    useDebouncedRippleCleanUp({
        rippleCount: rippleArray.length,
        duration,
        cleanUpFunction: () => {
            setRippleArray([]);
        },
    });

    const addRipple = (
        event:
            | React.MouseEvent<HTMLDivElement, MouseEvent>
            | React.TouchEvent<HTMLDivElement>
    ) => {
        const rippleContainer = event.currentTarget.getBoundingClientRect();
        const size =
            rippleContainer.width > rippleContainer.height
                ? rippleContainer.width
                : rippleContainer.height;

        let x, y;

        if ('touches' in event) {
            x = event.touches[0].pageX - rippleContainer.left - size / 2;
            y = event.touches[0].pageY - rippleContainer.top - size / 2;
        } else {
            x = event.pageX - rippleContainer.left - size / 2;
            y = event.pageY - rippleContainer.top - size / 2;
        }

        const newRipple = {
            x,
            y,
            size,
        };

        setRippleArray([...rippleArray, newRipple]);
    };

    return (
        <RippleContainer
            duration={duration}
            color={color}
            onMouseDown={addRipple}
        >
            {rippleArray.length > 0 &&
                rippleArray.map((ripple, index) => {
                    return (
                        <span
                            key={'span' + index}
                            style={{
                                top: ripple.y,
                                left: ripple.x,
                                width: ripple.size,
                                height: ripple.size,
                            }}
                        />
                    );
                })}
        </RippleContainer>
    );
};

export default Ripple;
