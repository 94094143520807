import {
    CSSProperties,
    ComponentProps,
    FC,
    ReactElement,
    cloneElement,
} from 'react';
import { FormErrorMessage } from './FormErrorMessage';
import styled from '@emotion/styled';
import { FormLabel } from './FormLabel';
import { UIVariant } from '../styles';

type CustomProps = {
    children: ReactElement;
    name?: string;
    label?: string;
    errorMessage?: string;
    isRequired?: boolean;
    variant?: UIVariant;
};

export type FormControlProps = CustomProps & ComponentProps<'div'>;

const Field = styled.div``;

const Mandatory = styled.span`
    color: red;
`;

export const FormControl: FC<FormControlProps> = ({
    variant = UIVariant.Filled,
    children,
    name,
    label,
    errorMessage,
    isRequired,
    ...rest
}) => {
    const childrenStyle: CSSProperties = {
        borderColor: errorMessage ? 'red' : undefined,
    };

    return (
        <Field {...rest}>
            {label && (
                <FormLabel htmlFor={name} variant={variant}>
                    {label}
                    {isRequired && <Mandatory>*</Mandatory>}
                </FormLabel>
            )}
            {cloneElement(children, {
                style: childrenStyle,
                variant: variant,
            })}
            {errorMessage && <FormErrorMessage message={errorMessage} />}
        </Field>
    );
};
