import { StyledProps } from '@emotion/react';
import styled from '@emotion/styled';
import { UIVariant } from '../styles';

export const FormLabel = styled.label<StyledProps<{ variant?: UIVariant }>>`
    padding-left: 0.5rem;
    display: inline-block;
    text-align: left;
    line-height: normal;
    font-weight: bold;
    font: ${({ theme }) => theme.fonts.body};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    ${({ variant, theme }) =>
        variant === UIVariant.DarkTranslucent
            ? { color: theme.palette.common.white }
            : { color: theme.palette.text.primary }}
`;
