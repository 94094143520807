import {
    Enum_Componentformfieldsinput_Type,
    InputFieldFragment,
} from '@graphql/generated/graphql';
import { FormFieldOrConsent, SinglePageFormElementsDynamicZone } from '@types';
import { isFormFieldOrConsent } from './guards';
import { getDefaultEmailConsent } from '@utils/consents';
import { getFieldKey } from './getFieldKey';

const getInputFieldInitialValue = (field: InputFieldFragment) => {
    switch (field.Type) {
        case Enum_Componentformfieldsinput_Type.Box:
            return false;

        case Enum_Componentformfieldsinput_Type.Text:
        case Enum_Componentformfieldsinput_Type.Number:
        case Enum_Componentformfieldsinput_Type.Email:
        case Enum_Componentformfieldsinput_Type.Password:
        case Enum_Componentformfieldsinput_Type.Rating:
        case Enum_Componentformfieldsinput_Type.Tel:
        default:
            return field.DefaultValue ?? undefined;
    }
};

const getInitialValue = (field: FormFieldOrConsent) => {
    switch (field.__typename) {
        case 'ComponentFormFieldsInput':
            return getInputFieldInitialValue(field);

        case 'ComponentFormFieldsDateInput':
            return field.DateDefaultValue ?? undefined;

        case 'ComponentConsentsEmail':
            return getDefaultEmailConsent(field);

        case 'ComponentFormFieldsSingleSelect':
        default:
            return undefined;
    }
};

export const getInitialValues = (fields: SinglePageFormElementsDynamicZone) => {
    let index = 0;

    return fields.reduce((acc, field) => {
        if (!isFormFieldOrConsent(field)) {
            return acc;
        }

        return {
            ...acc,
            [getFieldKey(field, index++)]: getInitialValue(field),
        };
    }, {});
};
