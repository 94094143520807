import {
    MultiPagesFormComponentFragment,
    SinglePageFormComponentFragment,
} from '@graphql/generated/graphql';
import { getCustomFormType } from '@utils/form/custom';
import { useMemo } from 'react';
import { SinglePageCustomForm } from './SinglePageCustomForm';
import {
    MultiPagesForm,
    SinglePageCustomForm as SinglePageCustomFormType,
    SinglePageTemplateForm as SinglePageTemplateFormType,
} from '@types';
import { SinglePageTemplateForm } from './SinglePageTemplateForm';
import {
    CustomFormType,
    EventTriggerSource,
    OutboundLinkContext,
} from '@enums';
import { UseFormSubmitMutate } from '@hooks';
import Survey from '@lib/pimster-surveys/Survey';
import { CustomerInputType } from '@hello-pimster/pimster-crm-sdk';

type Props = SinglePageFormProps | MultiPagesFormProps;

interface CommonProps {
    customerInputType?: CustomerInputType;
    outboundLinkContext: OutboundLinkContext;
}

interface SinglePageFormProps extends CommonProps {
    form: SinglePageFormComponentFragment['Form'];
    isSubmitting: boolean;
    onSubmit: UseFormSubmitMutate;
}

interface MultiPagesFormProps extends CommonProps {
    form: MultiPagesFormComponentFragment['Form'];
    triggerSource: EventTriggerSource;
    onPrevious: () => void;
    onNext: () => void;
}

export const CustomForm = (props: Props) => {
    const formType = useMemo(() => getCustomFormType(props.form), [props.form]);

    switch (formType) {
        case CustomFormType.SinglePageCustom: {
            const singlePageProps = props as SinglePageFormProps;

            return (
                <SinglePageCustomForm
                    form={singlePageProps.form as SinglePageCustomFormType}
                    outboundLinkContext={singlePageProps.outboundLinkContext}
                    isSubmitting={singlePageProps.isSubmitting}
                    onSubmit={singlePageProps.onSubmit}
                />
            );
        }

        case CustomFormType.SinglePageTemplate: {
            const singlePageProps = props as SinglePageFormProps;

            return (
                <SinglePageTemplateForm
                    form={singlePageProps.form as SinglePageTemplateFormType}
                    customerInputType={singlePageProps.customerInputType}
                    isSubmitting={singlePageProps.isSubmitting}
                    onSubmit={singlePageProps.onSubmit}
                />
            );
        }

        case CustomFormType.MultiPages: {
            const multiPagesForm = props as MultiPagesFormProps;

            return (
                <Survey
                    survey={multiPagesForm.form as MultiPagesForm}
                    triggerSource={multiPagesForm.triggerSource}
                    methods={{
                        onSurveyQuit: multiPagesForm.onPrevious,
                        onSurveySuccess: multiPagesForm.onNext,
                    }}
                />
            );
        }

        default:
            return null;
    }
};
