import { FC } from 'react';
import styled from '@emotion/styled';

type StepIndicatorProps = {
    current: number;
    total: number;
    text?: string;
};

const Background = styled.div`
    // theme color with less opacity
    background: ${({ theme }) => theme.palette.primary.main}80;
    border-radius: ${({ theme }) => theme.shape.radius.default};
    padding: ${({ theme }) => theme.spacing.xs};
    width: fit-content;
`;

const Text = styled.p`
    font: ${(props) => props.theme.fonts.captionMedium};
    color: ${({ theme }) => theme.palette.primary.contrastText};
`;

const StepIndicator: FC<StepIndicatorProps> = ({ current, total, text }) => (
    <Background>
        <Text>{text ? text : `${current} / ${total}`}</Text>
    </Background>
);

export default StepIndicator;
