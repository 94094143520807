import {
    CSSObject,
    SerializedStyles,
    StyledProps,
    Theme,
    css,
} from '@emotion/react';
import styled from '@emotion/styled';
import { ComponentProps } from 'react';
import { UIVariant } from '../styles';

type CustomProps = {
    variant?: UIVariant;
    isInvalid?: boolean;
};

export type InputProps = CustomProps & ComponentProps<'input'>;

const getVariant = ({
    theme,
    variant = UIVariant.Outline,
}: CustomProps & { theme: Theme }): SerializedStyles => {
    const styles: (string | CSSObject)[] = [];

    switch (variant) {
        case UIVariant.Filled:
            styles.push({
                border: `1px solid ${theme.palette.grey[200]}`,
                backgroundColor: theme.palette.background.alt,
            });
            break;
        case UIVariant.DarkTranslucent:
            styles.push(
                {
                    backgroundColor:
                        theme.palette.common.black + theme.opacity['75_hex'],
                    backdropFilter: theme.blurs.default,
                    color: theme.palette.common.white,
                    border: `1px solid ${
                        theme.palette.common.black + theme.opacity['75_hex']
                    };`,
                    borderRadius: theme.shape.radius.round,
                },
                `
                    ::-webkit-input-placeholder {
                        color: ${theme.palette.common.white};
                        opacity: 85%;
                    }

                    ::-moz-placeholder {
                        color: ${theme.palette.common.white};
                        opacity: 85%;
                    }

                    ::-ms-placeholder {
                        color: ${theme.palette.common.white};
                        opacity: 85%;
                    }

                    ::placeholder {
                        color: ${theme.palette.common.white};
                        opacity: 85%;
                    }
                `
            );
            break;
        case UIVariant.Outline:
        default:
            styles.push({
                border: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
            });
            break;
    }

    return css(...styles);
};

export const getInputStyling = ({
    theme,
    variant = UIVariant.Outline,
}: CustomProps & { theme: Theme }): SerializedStyles => css`
    width: 100%;
    padding-left: 1rem;
    &:focus {
        outline: none;
    }
    font: ${theme.fonts.body};
    padding-block: ${theme.spacing.xs};
    border-radius: ${theme.shape.radius.default};
    background: ${theme.palette.background.paper};
    ${getVariant({
        theme,
        variant,
    })};
`;

export const Input = styled.input<StyledProps<InputProps, 'input'>>`
    display: block;
    border-color: ${({ isInvalid }) => (isInvalid ? 'red' : undefined)};
    ${getInputStyling};
`;
