import styled from '@emotion/styled';
import MdText from '@components/utils/mdText';
import SurveyPageForm from './SurveyPageForm';
import { useSurveyContext } from '../contexts/SurveyContext';
import { StyledProps } from '@emotion/react';
import Icon from '@components/utils/icons/icon';
import { Centered } from '../styles';
import { ComponentDisplayRichText } from '@graphql/generated/graphql';

const Container = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
`;

const VStack = styled.div<{ isInfoPage: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.sm};
    height: ${({ isInfoPage }) => (isInfoPage ? '100%' : 'auto')};
`;

const StyledIcon = styled(Icon)<StyledProps<{ isHidden?: boolean }>>`
    visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
    position: absolute;
    top: ${(props) => props.theme.spacing.sm};
    left: 0;
    cursor: pointer;
`;

const SurveyPageBody: React.FC = () => {
    const { currentSurveyPage, isInfoPage, goToPreviousSurveyPage } =
        useSurveyContext();

    return (
        <Container>
            <VStack isInfoPage={isInfoPage}>
                {isInfoPage && (
                    <>
                        <StyledIcon
                            name='keyboard_arrow_left'
                            size='medium'
                            onClick={goToPreviousSurveyPage}
                        />
                        <Centered>
                            <MdText
                                markdown={
                                    (
                                        currentSurveyPage!
                                            .Elements[0] as ComponentDisplayRichText
                                    ).RichText
                                }
                            />
                        </Centered>
                    </>
                )}
                {!isInfoPage && <SurveyPageForm />}
            </VStack>
        </Container>
    );
};

export default SurveyPageBody;
