import AppLayout from '@components/layouts/appLayout';
import SurveyPageHeader from './SurveyPageHeader';
import SurveyPageBody from './SurveyPageBody';
import SurveyPageFooter from './SurveyPageFooter';
import { useSurveyContext } from '../contexts/SurveyContext';

const SurveyPage = () => {
    const { currentStep } = useSurveyContext();
    return (
        <AppLayout
            header={<SurveyPageHeader />}
            body={<SurveyPageBody />}
            footer={<SurveyPageFooter />}
            hasFixedFooter={true}
            footerHasPointerEvents={false}
            scrollToTopDependencies={[currentStep]}
        />
    );
};

export default SurveyPage;
