import { CGU } from '@components/consents/CGU';
import { EmailConsent } from '@components/consents/EmailConsent';
import { FORM, ONBOARDING } from '@constants';
import styled from '@emotion/styled';
import { OutboundLinkContext, TrackEvent } from '@enums';
import { ComponentConsentsEmail } from '@graphql/generated/graphql';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { UIVariant, Button, FormControl, Input, VStack } from '@lib/uikit';
import { getDefaultEmailConsent } from '@utils/consents';
import { Field, FieldProps, Form as FormikForm, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';
import * as yup from 'yup';
import { UseFormSubmitMutate } from '@hooks';
import { CustomerInputType } from '@hello-pimster/pimster-crm-sdk';
import { TextVariant } from '@lib/uikit/styles/text';

interface Props {
    formName: string;
    formUID: string;
    customerInputType: CustomerInputType;
    companyName: string;
    ctaText?: string;
    emailConsent?: ComponentConsentsEmail;
    withCGU?: boolean;
    isDisabled?: boolean;
    isError?: boolean;
    isSubmitting: boolean;
    onSubmit: UseFormSubmitMutate;
    variant?: UIVariant;
}

interface FormData {
    email: string;
    emailOptin: boolean;
}

const Form = styled(FormikForm)`
    width: 100%;
`;

export const RegistrationForm: FC<Props> = ({
    formName,
    formUID,
    customerInputType,
    companyName,
    ctaText,
    emailConsent,
    withCGU,
    isDisabled,
    isError,
    isSubmitting,
    onSubmit,
    variant = UIVariant.Outline,
}) => {
    const { t } = useTranslation([ONBOARDING, FORM]);
    const { track } = useAnalytics();
    const [formHasBeenFocused, setFormHasBeenFocused] =
        useState<boolean>(false);

    const buttonText = isError
        ? t('submission_error')
        : ctaText || t('save_product');

    const initialValues: FormData = {
        email: '',
        emailOptin: getDefaultEmailConsent(emailConsent),
    };

    const validationSchema = () => {
        const emailOptin = yup.boolean();

        if (emailConsent?.IsRequired) {
            emailOptin.oneOf([true], t('errors.required'));
        }

        return yup.object({
            email: yup
                .string()
                .email(t(`${FORM}:errors.email.format`))
                .required(t(`${FORM}:errors.required`)),
            emailOptin,
        });
    };

    const handleFocus = () => {
        if (!formHasBeenFocused) {
            track(TrackEvent.FormStarted, {
                name: formName,
                form_uid: formUID,
            });
        }

        setFormHasBeenFocused(true);
    };

    return (
        <Formik
            validateOnBlur
            validateOnMount={false}
            validateOnChange={false}
            initialValues={initialValues}
            validationSchema={validationSchema()}
            onSubmit={({ email, emailOptin }) =>
                onSubmit({ data: { email, emailOptin }, customerInputType })
            }
        >
            <Form onFocus={handleFocus}>
                <VStack spacing='xs'>
                    <Field type='email' name='email'>
                        {({ form, field }: FieldProps) => (
                            <FormControl
                                name='email'
                                errorMessage={form.errors.email as string}
                                variant={variant}
                            >
                                <Input
                                    {...field}
                                    type='email'
                                    placeholder={
                                        t(`${FORM}:labels.email`) + '*'
                                    }
                                    variant={variant}
                                />
                            </FormControl>
                        )}
                    </Field>
                    {emailConsent && (
                        <EmailConsent
                            companyName={companyName}
                            element={emailConsent}
                            outboundLinkContext={
                                OutboundLinkContext.RegistrationForm
                            }
                            textVariant={
                                variant === UIVariant.DarkTranslucent
                                    ? TextVariant.Dark
                                    : undefined
                            }
                        />
                    )}
                    <Button
                        isFullWidth
                        type='submit'
                        disabled={isError || isDisabled}
                        isLoading={isSubmitting}
                        colorScheme={
                            variant === UIVariant.DarkTranslucent
                                ? 'white'
                                : 'black'
                        }
                    >
                        {buttonText}
                    </Button>
                    {withCGU && (
                        <CGU
                            textVariant={
                                variant === UIVariant.DarkTranslucent
                                    ? TextVariant.Dark
                                    : undefined
                            }
                            outboundLinkContext={
                                OutboundLinkContext.RegistrationForm
                            }
                        />
                    )}
                </VStack>
            </Form>
        </Formik>
    );
};
