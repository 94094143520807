import { Theme } from '@emotion/react';
import { TypographyElement, TypographyVariant } from '@lib/uikit/theme/types';
import { isObject } from 'formik';
import { ExcludedTypographyKeys, Fonts } from '@lib/uikit/theme/types';

const excludedKeys: ExcludedTypographyKeys[] = [
    'fontFamily',
    'fontSize',
    'fontWeight',
    'lineHeight',
    'fontStyle',
];

const createFont = ({
    fontStyle,
    fontWeight,
    fontSize,
    lineHeight,
    fontFamily,
    textTransform,
}: TypographyElement) => {
    let font = `${fontStyle} ${fontWeight} ${fontSize}/${lineHeight} ${fontFamily}`;

    if (textTransform) {
        font += ` ${textTransform}`;
    }

    return font;
};

const isTypographyElement = (value: unknown): value is TypographyElement => {
    return (
        isObject(value) &&
        'fontStyle' in value &&
        'fontWeight' in value &&
        'fontSize' in value &&
        'lineHeight' in value &&
        'fontFamily' in value
    );
};

const isTypographyVariant = (value: unknown): value is TypographyVariant => {
    return typeof value === 'string';
};

export const getFonts = (typography: Theme['typography']) => {
    const fonts = {} as Fonts;

    Object.entries(typography).forEach(([key, value]) => {
        if (isTypographyVariant(key) && isTypographyElement(value)) {
            fonts[key] = createFont(value);
        }
    });

    excludedKeys.forEach(() => {
        fonts['font'] = createFont(typography);
    });

    return fonts;
};
