import styled from '@emotion/styled';
import { CSSProperties, ComponentProps } from 'react';
import { getInputStyling } from './input/Input';
import { UIVariant } from './styles';

type CustomProps = {
    variant?: UIVariant;
    isInvalid?: boolean;
    resize?: CSSProperties['resize'];
    height?: CSSProperties['height'];
    maxHeight?: CSSProperties['maxHeight'];
};

export type TextareaProps = CustomProps & ComponentProps<'textarea'>;

export const Textarea = styled.textarea<TextareaProps>`
    cursor: inherit;
    ${getInputStyling};
    resize: ${({ resize }) => resize};
    height: ${({ height }) => height};
    max-height: ${({ maxHeight }) => maxHeight};
`;
