import React, { ImgHTMLAttributes } from 'react';
import getSrcSets from './utils';
import { Formats } from './utils/types';

export type PropsType = React.DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
> & { formats: Formats };

const StrapiImage: React.FC<PropsType> = ({
    src,
    alt,
    className,
    formats,
    sizes,
    ...imageProps
}) => (
    <picture {...imageProps}>
        <source
            type='image/webp'
            sizes={sizes}
            srcSet={getSrcSets(formats).webp || ''}
        />
        <img
            className={className}
            src={src}
            sizes={sizes}
            alt={alt || ''}
            srcSet={getSrcSets(formats).regular || ''}
            {...imageProps}
        />
    </picture>
);

export default StrapiImage;
