import { Button } from '@lib/uikit';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { COMMON } from '@constants';
import { useSurveyContext } from '../contexts/SurveyContext';
import { useFormContext } from '../contexts/FormContext';
import { CGU } from '@components/consents/CGU';
import { OutboundLinkContext } from '@enums';
import { useFormikContext } from 'formik';
import { FormStatus } from '@types';

const Container = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
    padding: ${({ theme }) => theme.spacing.md};
    background-color: ${({ theme }) => theme?.palette.background?.default};
`;

const Stack = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.theme.spacing.sm};
`;

const SurveyPageFooter = () => {
    const { currentSurveyPage, isRedirecting } = useSurveyContext();
    const { continueSurvey, isSubmitting, isContinueDisabled } =
        useFormContext();
    const { t } = useTranslation(COMMON);
    const { status } = useFormikContext();

    return (
        <Container>
            <Stack>
                <Button
                    isFullWidth
                    onClick={continueSurvey}
                    disabled={
                        isContinueDisabled ||
                        status === FormStatus.UPLOADING ||
                        status === FormStatus.DISABLED
                    }
                    isLoading={isSubmitting || isRedirecting}
                >
                    {currentSurveyPage?.CtaText || t('continue')}
                </Button>
                {currentSurveyPage?.WithCGU && (
                    <CGU outboundLinkContext={OutboundLinkContext.Footer} />
                )}
            </Stack>
        </Container>
    );
};

export default SurveyPageFooter;
