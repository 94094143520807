import styled from '@emotion/styled';
import { FileInputFieldFragment } from '@graphql/generated/graphql';
import { useApiCrm } from '@lib/pimster-crm/provider/ApiCrmProvider';
import { FormStatus } from '@types';
import { useFormikContext } from 'formik';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import Icon from '../icons/icon';
import { Body, Caption } from '../text';
import {
    ONE_MB_IN_DECIMAL,
    FORM_FILE_UPLOAD_DEFAULT_ACCEPTS,
    FORM_FILE_UPLOAD_DEFAULT_MAX_SIZE,
    FORM,
    FORM_FILE_UPLOAD_ATTACHMENT_PREFIX,
} from '@constants';
import { FormControl } from '@lib/uikit';
import { useTheme } from '@emotion/react';
import { useTranslation } from 'next-i18next';

type PropsType = {
    element: FileInputFieldFragment;
};

export type AcceptedFileTypes = {
    [mimetype: string]: string[];
};

const isAcceptedFileTypes = (value: unknown): value is AcceptedFileTypes => {
    if (typeof value !== 'object') return false;
    if (value === null) return false;
    if (Array.isArray(value)) return false;
    if (
        !Object.keys(value).every((mimetype) =>
            Array.isArray((value as { [key: string]: unknown })[mimetype])
        )
    ) {
        return false;
    }

    return true;
};

const Dropzone = styled.div<{ isSuccess?: boolean }>`
    background: ${({ theme }) => theme.palette.background.alt};
    border-radius: ${({ theme }) => theme.shape.radius.default};
    border: ${({ theme }) => theme.borders[2]};
    border-style: ${({ isSuccess }) => (isSuccess ? 'solid' : 'dashed')};
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: ${({ theme }) => theme.spacing.xs};

    padding-block: ${({ theme }) => theme.spacing.xs};
`;

const DropzoneText = styled.div`
    font: ${({ theme }) => theme.fonts.body};
    padding-left: ${({ theme }) => theme.spacing.xs};
    padding-right: ${({ theme }) => theme.spacing.xs};
    > * {
        text-align: center;
        word-break: break-word;
    }
`;

const uniqueFileExtensions: (
    acceptedFileTypes: AcceptedFileTypes
) => string[] = (acceptedFileTypes: AcceptedFileTypes): string[] => {
    return Array.from(
        Object.keys(acceptedFileTypes).reduce<string[]>(
            (acc, mimetype) => acc.concat(acceptedFileTypes[mimetype]),
            []
        )
    );
};

export const attachmentFieldKey = (name: string) =>
    FORM_FILE_UPLOAD_ATTACHMENT_PREFIX + name;

const FileInput = ({ element }: PropsType) => {
    const { t } = useTranslation(FORM);
    const theme = useTheme();
    const fieldName = attachmentFieldKey(element.Name);
    const { setStatus, setFieldValue, errors, setFieldError } =
        useFormikContext<Record<string, unknown>>();
    const { fileUploadClient } = useApiCrm();
    const {
        query: { company },
    } = useRouter();

    const uploadFile = async (file: File) => {
        try {
            setStatus(FormStatus.UPLOADING);
            const resp =
                await fileUploadClient.customerInputControllerAttachment({
                    metadata: {},
                    name: '',
                    company: {
                        cmsSlug: Array.isArray(company)
                            ? company.at(1)
                            : company,
                    },
                    file,
                });
            setFieldValue(fieldName, {
                UUID: resp.data.UUID,
            });
        } catch (err) {
            console.error(
                '[FileInput] An error occurred while uploading file.',
                err
            );
        }
        setStatus(FormStatus.READY);
    };

    const onDropAccepted = async (files: File[]) => {
        await Promise.all(files.map(uploadFile));
        setFieldError(fieldName, undefined);
    };
    const onDropRejected = (fileRejections: FileRejection[]) => {
        const errorMessage = fileRejections
            .at(0)
            ?.errors.reduce<string[]>(
                (acc, error) => [...acc, t(`files.errors.${error.code}`)],
                []
            )
            .join(', ');
        setFieldError(fieldName, errorMessage);
    };
    const onError = (err: Error) => {
        setFieldError(fieldName, err.message);
        console.error(err);
    };

    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
        accept: element.AcceptedFileTypes ?? FORM_FILE_UPLOAD_DEFAULT_ACCEPTS,
        maxFiles: 1,
        multiple: false,
        maxSize: FORM_FILE_UPLOAD_DEFAULT_MAX_SIZE,
        onDropRejected,
        onError,
        onDropAccepted,
    });

    useEffect(() => {
        setStatus(FormStatus.READY);
    }, [setStatus]);

    const fileExtensions: string[] = isAcceptedFileTypes(
        element.AcceptedFileTypes
    )
        ? uniqueFileExtensions(element.AcceptedFileTypes)
        : uniqueFileExtensions(FORM_FILE_UPLOAD_DEFAULT_ACCEPTS);

    return (
        <FormControl
            label={element.Label}
            isRequired={element.IsRequired}
            errorMessage={errors[fieldName]}
        >
            <Dropzone isSuccess={acceptedFiles.length > 0} {...getRootProps()}>
                <input {...getInputProps()} />
                <Icon
                    name={acceptedFiles.length > 0 ? 'cloud_done' : 'backup'}
                    size='large'
                    color={
                        acceptedFiles.length > 0
                            ? theme.palette.success.main
                            : undefined
                    }
                />
                <DropzoneText>
                    <Body>
                        {acceptedFiles.length > 0
                            ? acceptedFiles.at(0)?.name
                            : t('files.select')}
                    </Body>
                    {acceptedFiles.length === 0 && (
                        <Caption>
                            {fileExtensions.length
                                ? fileExtensions.join(', ') + ' - '
                                : ''}
                            {t('files.maxSize', {
                                maxSizeInMB:
                                    FORM_FILE_UPLOAD_DEFAULT_MAX_SIZE /
                                    ONE_MB_IN_DECIMAL,
                            })}
                        </Caption>
                    )}
                </DropzoneText>
            </Dropzone>
        </FormControl>
    );
};

export default FileInput;
