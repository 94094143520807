import {
    AnalyticsBrowser,
    Context,
    InitOptions,
} from '@segment/analytics-next';
import {
    EventParams,
    PageParams,
} from '@segment/analytics-next/dist/types/core/arguments-resolver';
import { GIVEN_CONSENT, REJECTED_CONSENT } from './constants';

// A wrapper implementation of the AnalyticsBrowser that can be deactivated
export class AnalyticsBrowserWrapper extends AnalyticsBrowser {
    private writeKey: string;
    constructor(enabled: boolean) {
        super();
        this.writeKey = process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY ?? '';
        if (this.writeKey === '')
            console.error('[Analytics] Missing write key');

        if (!enabled) {
            if (typeof window === 'undefined') return;
            console.warn(
                '[Analytics] Disabled. Track & Page calls will be ignored.'
            );
        }

        this.track = enabled
            ? this.track
            : (...args: EventParams): Promise<Context> => {
                  if (args.length > 2)
                      console.error(
                          '[Analytics] Track overwrite currently only support 2 argument'
                      );
                  const name = args[0] as string;
                  const properties = args[1] as object;

                  return Promise.resolve(
                      new Context({
                          name,
                          properties,
                          type: 'track',
                      })
                  );
              };

        this.page = enabled
            ? this.page
            : (...args: PageParams): Promise<Context> => {
                  if (args.length > 1)
                      console.error(
                          '[Analytics] Page overwrite currently only support unique argument'
                      );
                  const page = args[0] as string;
                  return Promise.resolve(
                      new Context({
                          type: 'page',
                          name: page,
                      })
                  );
              };
    }

    _load = (options: InitOptions = {}) => {
        return this.load({ writeKey: this.writeKey }, { ...options });
    };

    loadWithConsent = (options: InitOptions = {}): AnalyticsBrowser => {
        console.log('[Analytics] Load with consent');
        return this._load({ ...options, ...GIVEN_CONSENT });
    };

    loadWithoutConsent = (options: InitOptions = {}): AnalyticsBrowser => {
        console.log('[Analytics] Load without consent');
        return this._load({ ...options, ...REJECTED_CONSENT });
    };
}
