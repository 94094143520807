import { AriaRole, MouseEventHandler, ReactNode } from 'react';
import Link, { LinkProps } from 'next/link';
import styled from '@emotion/styled';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { TrackEvent } from '@enums';

type PropsType = {
    children: ReactNode;
    href: LinkProps['href'];
    as?: LinkProps['as'];
    className?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    scroll?: boolean;
    role?: AriaRole;
    title?: string;
};

const SLink = styled(Link)`
    text-decoration: none;
`;

const InternalLink = ({
    children,
    href,
    as,
    className,
    onClick,
    scroll = true,
    role,
    title,
}: PropsType) => {
    const { track } = useAnalytics();

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.stopPropagation();
        track(TrackEvent.InboundLink, {
            category: 'click',
            destination: href,
        });

        if (onClick) {
            onClick(event);
        }
    };

    return (
        <SLink
            href={href}
            as={as}
            passHref
            scroll={scroll}
            className={className}
            onClick={handleClick}
            tabIndex={0}
            role={role}
            title={title}
        >
            {children}
        </SLink>
    );
};

export default InternalLink;
