import RadioCardSelectField from '@components/utils/fields/radioCardSelectField';
import { SingleSelect } from '@components/utils/fields/singleSelectField';
import FileInput from '@components/utils/inputElements/FileInput';
import { SingleSelectDisplayType } from '@graphql/generated/graphql';
import { DynamicFormSchemaType, FormFieldComponent } from '@types';
import { useFormikContext } from 'formik';
import { RenderCustomInput } from '@components/form/custom/RenderCustomInput';
import { DateInput } from '@components/utils/inputElements/date/dateInput';

interface Props {
    fieldComponent: FormFieldComponent;
}

export const RenderFormFieldComponent = ({ fieldComponent }: Props) => {
    const formikProps = useFormikContext<DynamicFormSchemaType>();

    switch (fieldComponent.__typename) {
        case 'ComponentFormFieldsInput':
            return <RenderCustomInput element={fieldComponent} />;

        case 'ComponentFormFieldsFileInput':
            return <FileInput element={fieldComponent} />;

        case 'ComponentFormFieldsDateInput':
            return <DateInput element={fieldComponent} />;

        case 'ComponentFormFieldsSingleSelect': {
            if (
                fieldComponent.DisplayType === SingleSelectDisplayType.Dropdown
            ) {
                return (
                    <SingleSelect
                        choices={fieldComponent.Values}
                        placeholder={fieldComponent.Placeholder}
                        isRequired={fieldComponent.IsRequired}
                        label={fieldComponent.Label}
                        {...formikProps?.getFieldProps(
                            `['${fieldComponent.Name}']`
                        )}
                        errorMessage={
                            formikProps?.errors[fieldComponent.Name] as
                                | string
                                | undefined
                        }
                    />
                );
            }

            return (
                <RadioCardSelectField
                    choices={fieldComponent.Values}
                    multipleChoice={false}
                    selectedChoices={[
                        formikProps?.getFieldProps(`['${fieldComponent.Name}']`)
                            .value,
                    ]}
                    onChange={(value) => {
                        formikProps?.setFieldValue(
                            `['${fieldComponent.Name}']`,
                            value[0]
                        );
                    }}
                />
            );
        }
    }
};
