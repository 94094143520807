import { Global, css } from '@emotion/react';
import { FC } from 'react';
import globals from './styles/globals';
import fonts from './styles/fonts';

const GlobalStyles: FC = () => (
    <Global
        styles={(theme) => css`
            ${globals(theme)}
            ${fonts(theme)}
        `}
    />
);

export default GlobalStyles;
