import { FormContextProvider } from './contexts/FormContext';
import { SurveyMethod } from './types';
import SurveyPage from './components/SurveyPage';
import { EventTriggerSource } from '@enums';
import { SurveyContextProvider } from './contexts/SurveyContext';
import { SurveyPageContextProvider } from './contexts/SurveyPageContext';
import { MultiPagesForm } from '@types';

type PropsType = {
    survey: MultiPagesForm;
    methods: SurveyMethod;
    isRedirecting?: boolean;
    triggerSource?: EventTriggerSource;
};

const Survey: React.FC<PropsType> = ({
    survey,
    methods,
    isRedirecting = false,
    triggerSource = EventTriggerSource.MODULE,
}) => {
    return (
        <SurveyContextProvider
            survey={survey}
            isRedirecting={isRedirecting}
            methods={methods}
            triggerSource={triggerSource}
        >
            <SurveyPageContextProvider>
                <FormContextProvider>
                    <SurveyPage />
                </FormContextProvider>
            </SurveyPageContextProvider>
        </SurveyContextProvider>
    );
};

export default Survey;
