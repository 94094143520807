import { EmailConsent } from '@components/consents/EmailConsent';
import { FORM, NEWSLETTER } from '@constants';
import styled from '@emotion/styled';
import { OutboundLinkContext, TrackEvent } from '@enums';
import { ComponentConsentsEmail } from '@graphql/generated/graphql';
import { Button, FormControl, Input, VStack } from '@lib/uikit';
import { getDefaultEmailConsent } from '@utils/consents';
import { Field, FieldProps, Form as FormikForm, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { FC, useState } from 'react';
import * as yup from 'yup';
import { Body, Heading2 } from '@components/utils/text';
import { UseFormSubmitMutate } from '@hooks';
import { useAnalytics } from '@lib/analytics/analyticsContext';

interface Props {
    formName: string;
    formUID: string;
    companyName: string;
    title?: string;
    description?: string;
    isNameRequired: boolean;
    emailConsent?: ComponentConsentsEmail;
    isSubmitting: boolean;
    onSubmit: UseFormSubmitMutate;
}

interface FormData {
    email: string;
    firstname?: string;
    lastname?: string;
    emailOptin?: boolean;
}

const Form = styled(FormikForm)`
    width: 100%;
`;

export const NewsletterForm: FC<Props> = ({
    formName,
    formUID,
    title,
    description,
    isNameRequired,
    emailConsent,
    companyName,
    isSubmitting,
    onSubmit,
}) => {
    const { track } = useAnalytics();
    const { t } = useTranslation([FORM, NEWSLETTER]);
    const [formHasBeenFocused, setFormHasBeenFocused] =
        useState<boolean>(false);

    const defaultInitialValues: FormData = {
        email: '',
        emailOptin: getDefaultEmailConsent(emailConsent),
    };

    const requiredNameInitialValues: FormData = {
        ...defaultInitialValues,
        firstname: '',
        lastname: '',
    };

    const createValidationSchema = (isRequired?: boolean) => {
        const emailOptinSchema = yup.boolean();

        if (isRequired) {
            emailOptinSchema.oneOf([true], t('errors.required'));
        }

        return yup.object({
            email: yup
                .string()
                .email(t('errors.email.format'))
                .required(t('errors.required')),
            emailOptin: emailOptinSchema,
        });
    };

    const defaultValidationSchema = createValidationSchema(
        emailConsent?.IsRequired
    );

    const requiredNameValidationSchema = isNameRequired
        ? defaultValidationSchema.concat(
              yup.object({
                  firstname: yup.string().required(t('errors.required')),
                  lastname: yup.string().required(t('errors.required')),
              })
          )
        : defaultValidationSchema;

    const withCheckbox = emailConsent?.ImplicitConsent ? false : true;

    const RenderEmailConsent = () => {
        if (!emailConsent) return null;

        return (
            <EmailConsent
                companyName={companyName}
                element={emailConsent}
                outboundLinkContext={OutboundLinkContext.NewsletterModal}
            />
        );
    };

    const handleFocus = () => {
        if (!formHasBeenFocused) {
            track(TrackEvent.FormStarted, {
                name: formName,
                form_uid: formUID,
            });
        }

        setFormHasBeenFocused(true);
    };

    return (
        <VStack>
            <VStack spacing='xs' align='center' textAlign='center'>
                <Heading2>{title || t(`${NEWSLETTER}:titleDefault`)}</Heading2>
                <Body>
                    {description || t(`${NEWSLETTER}:descriptionDefault`)}
                </Body>
            </VStack>
            <Formik
                validateOnBlur={false}
                validateOnMount={false}
                validateOnChange={false}
                initialValues={
                    isNameRequired
                        ? requiredNameInitialValues
                        : defaultInitialValues
                }
                validationSchema={
                    isNameRequired
                        ? requiredNameValidationSchema
                        : defaultValidationSchema
                }
                onSubmit={(data) =>
                    onSubmit({
                        data: { ...data, consent: true },
                        customerInputType: 'CUSTOM',
                    })
                }
            >
                <Form onFocus={handleFocus}>
                    <VStack spacing='xs'>
                        <Field type='email' name='email'>
                            {({ form, field }: FieldProps) => (
                                <FormControl
                                    name='email'
                                    errorMessage={form.errors.email as string}
                                >
                                    <Input
                                        {...field}
                                        type='email'
                                        placeholder={t('labels.email') + '*'}
                                    />
                                </FormControl>
                            )}
                        </Field>
                        {isNameRequired && (
                            <>
                                <Field type='text' name='firstname'>
                                    {({ form, field }: FieldProps) => (
                                        <FormControl
                                            name='firstname'
                                            errorMessage={
                                                form.errors.firstname as string
                                            }
                                        >
                                            <Input
                                                {...field}
                                                type='text'
                                                placeholder={
                                                    t('labels.firstName') + '*'
                                                }
                                            />
                                        </FormControl>
                                    )}
                                </Field>
                                <Field type='text' name='lastname'>
                                    {({ form, field }: FieldProps) => (
                                        <FormControl
                                            name='lastname'
                                            errorMessage={
                                                form.errors.lastname as string
                                            }
                                        >
                                            <Input
                                                {...field}
                                                type='text'
                                                placeholder={
                                                    t('labels.lastName') + '*'
                                                }
                                            />
                                        </FormControl>
                                    )}
                                </Field>
                            </>
                        )}
                        <Button
                            isFullWidth
                            buttonSize='large'
                            colorScheme='black'
                            type='submit'
                            isLoading={isSubmitting}
                        >
                            {t('newsletter:subscribe')}
                        </Button>
                        {withCheckbox && <RenderEmailConsent />}
                    </VStack>
                </Form>
            </Formik>
        </VStack>
    );
};
