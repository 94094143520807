import styled from '@emotion/styled';
import { RedirectionType } from '@graphql/generated/graphql';
import { LinkButtonProps, getButtonStyle, getRippleColor } from '@lib/uikit';
import InternalLink from '../links/internalLink';
import NewTabLink from '../links/newTabLink';
import { useRouter } from 'next/router';
import { hrefFromAs } from '@utils/link';
import Ripple from '../ripple';
import { Theme, css } from '@emotion/react';
import {
    OutboundLinkContext,
    OutboundLinkDestinationCategory,
    OutboundLinkShape,
} from '@enums';

export interface BaseProps extends Omit<LinkButtonProps, 'theme'> {
    url: string;
    text: string;
    onClick?: () => void;
}
export interface OutboundProps extends BaseProps {
    type: RedirectionType.Outside;
    destinationName: string;
    context: OutboundLinkContext;
    destinationCategory: OutboundLinkDestinationCategory;
    shape: OutboundLinkShape;
}

export interface InboundProps extends BaseProps {
    type: RedirectionType.Inside;
}

export type Props = OutboundProps | InboundProps;
type GetPaddingBlockProps = {
    theme: Theme;
};

const getPaddingBlock = ({ theme }: GetPaddingBlockProps) => css`
    padding-block: ${theme.components.buttons.size === 'xs'
        ? theme.spacing.xs
        : theme.spacing.sm};
`;

const InternalLinkButton = styled(InternalLink)<Omit<LinkButtonProps, 'theme'>>`
    ${(props) => getButtonStyle(props)}
    ${getPaddingBlock}
`;

const NewTabLinkButton = styled(NewTabLink)<Omit<LinkButtonProps, 'theme'>>`
    ${(props) => getButtonStyle(props)}
    ${getPaddingBlock}
`;

const LinkButton: React.FC<Props> = (props: Props) => {
    const { url, text, type, onClick, buttonSize = 'large', ...rest } = props;
    const { company } = useRouter().query;

    if (typeof company !== 'string') return null;

    const rippleColor = getRippleColor({
        variant: rest.variant,
        colorScheme: rest.colorScheme,
    });

    return (
        <>
            {type === RedirectionType.Inside ? (
                <InternalLinkButton
                    href={hrefFromAs(url, company)}
                    as={url}
                    buttonSize={buttonSize}
                    {...rest}
                    onClick={onClick}
                >
                    {text}
                    <Ripple color={rippleColor} />
                </InternalLinkButton>
            ) : (
                <NewTabLinkButton
                    href={url}
                    destination_name={props.destinationName}
                    context={props.context}
                    shape={props.shape}
                    destination_category={props.destinationCategory}
                    {...rest}
                    onClick={onClick}
                >
                    {text}
                    <Ripple color={rippleColor} />
                </NewTabLinkButton>
            )}
        </>
    );
};

export default LinkButton;
