import { DateInputFieldFragment } from '@graphql/generated/graphql';
import { FormControl, Input } from '@lib/uikit';
import { DateFallbackInput } from './dateFallbackInput';
import { useDateInput } from './useDateInput';
import { fromISO, toISO } from '@utils/date';
import styled from '@emotion/styled';
import { isIOS } from 'react-device-detect';

type Props = {
    element: DateInputFieldFragment;
};

const DInput = styled(Input)`
    @supports (-webkit-overflow-scrolling: touch) {
        // Safari iOS only
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        min-height: 2.5rem;
    }
    ::-webkit-calendar-picker-indicator {
        // Move the icon on Chrome
        position: relative;
        right: ${({ theme }) => theme.spacing.md};
    }
`;

export const DateInput = ({ element }: Props) => {
    const {
        errors,
        fieldProps,
        isSupported,
        locale,
        value,
        handleChange,
        ref,
        setError,
    } = useDateInput({
        name: element.Name,
        defaultValue: element.DateDefaultValue
            ? fromISO(element.DateDefaultValue)
            : undefined,
    });

    return (
        <FormControl
            key={element.Name}
            name={element.Name}
            label={element.Label}
            isRequired={element.IsRequired}
            errorMessage={errors}
        >
            {isSupported ? (
                <DInput
                    ref={ref}
                    type='date'
                    {...fieldProps}
                    value={value ? toISO(value) : ''}
                    isInvalid={!!errors}
                    autoFocus={!isIOS}
                    onChange={(e) => {
                        if (e.target.value) {
                            const newDate = fromISO(e.target.value);
                            handleChange(newDate);
                        } else {
                            handleChange(null);
                        }
                    }}
                />
            ) : (
                <DateFallbackInput
                    fieldProps={fieldProps}
                    value={value}
                    locale={locale!}
                    errors={errors}
                    onValidDate={handleChange}
                    onError={setError}
                />
            )}
        </FormControl>
    );
};
