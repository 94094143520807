import styled from '@emotion/styled';
import Icon, { IconProps } from './icon';
import { StyledProps, useTheme } from '@emotion/react';

const PASTILLE_SIZES = {
    MOBILE: {
        normal: '29px',
        large: '36px',
    },
    DESKTOP: {
        normal: '33px',
        large: '40px',
    },
};

export type PastilleProps = Omit<IconProps, 'size'> & {
    backgroundColor?: string;
    size?: 'normal' | 'large';
};

export const Pastille = styled.div<StyledProps<PastilleProps>>`
    width: ${({ size }) => PASTILLE_SIZES.MOBILE[size!]};
    height: ${({ size }) => PASTILLE_SIZES.MOBILE[size!]};
    @media (min-width: 748px) {
        width: ${({ size }) => PASTILLE_SIZES.DESKTOP[size!]};
        height: ${({ size }) => PASTILLE_SIZES.DESKTOP[size!]};
    }
    border-radius: ${({ theme }) => theme.shape.radius.xs};
    @media (min-width: 748px) {
        border-radius: ${({ theme }) => theme.shape.radius.sm};
    }
    background-color: ${({ backgroundColor }) => backgroundColor};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
`;

export const IconPastille: React.FC<PastilleProps> = ({
    backgroundColor,
    size = 'normal',
    ...iconProps
}: PastilleProps) => {
    const theme = useTheme();

    return (
        <Pastille
            backgroundColor={backgroundColor || theme.palette.background.alt}
            size={size}
        >
            <Icon
                {...iconProps}
                size={size === 'normal' ? 'small' : 'medium'}
            />
        </Pastille>
    );
};
