import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Enum_Modals_Trigger } from '@graphql/generated/graphql';
import { getLastVisited, updateLastVisited } from '@utils/lastVisited';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { TrackEvent } from '@enums';
import { ROUTE_ARTICLE, ROUTE_SEARCH, ROUTE_STORY } from '@constants';

const InitRouter = () => {
    const { track } = useAnalytics();
    const router = useRouter();
    const {
        asPath,
        pathname,
        query: { company, product },
    } = router;

    useEffect(() => {
        const lastVisited = getLastVisited(company);

        if (!Object.keys(lastVisited).length) {
            track(TrackEvent.FirstVisit, {
                event: Enum_Modals_Trigger.FirstVisit,
                company: company as string,
            });
            updateLastVisited(company, 'global');
        }
    }, [company, track]);

    useEffect(() => {
        const triggerEvents = (targetUrl: string) => {
            const slugRegex = /(story|article)\/(?<slug>[\w-]+)/;

            // Handle exitStory
            if (pathname === ROUTE_STORY && !targetUrl.includes('/story/')) {
                track(TrackEvent.ExitStory, {
                    event: Enum_Modals_Trigger.ExitStory,
                    story_slug: slugRegex.exec(asPath)?.groups?.slug || '',
                });
            }

            // Handle enterStory
            if (pathname !== ROUTE_STORY && targetUrl.includes('/story/')) {
                track(TrackEvent.EnterStory, {
                    event: Enum_Modals_Trigger.EnterStory,
                    story_slug: slugRegex.exec(targetUrl)?.groups?.slug || '',
                });
            }

            // Handle exitArticle
            if (
                pathname === ROUTE_ARTICLE &&
                !targetUrl.includes('/article/')
            ) {
                track(TrackEvent.ExitArticle, {
                    event: Enum_Modals_Trigger.ExitArticle,
                    article_slug: slugRegex.exec(asPath)?.groups?.slug || '',
                });
            }

            // Handle enterArticle
            if (pathname !== ROUTE_ARTICLE && targetUrl.includes('/article/')) {
                track(TrackEvent.EnterArticle, {
                    event: Enum_Modals_Trigger.EnterArticle,
                    article_slug: slugRegex.exec(targetUrl)?.groups?.slug || '',
                });
            }

            // Handle search action
            if (pathname !== ROUTE_SEARCH && targetUrl.includes('/search')) {
                track(TrackEvent.EnterSearch, {
                    event: Enum_Modals_Trigger.OnSearch,
                    product: product as string,
                });
            }
        };

        router.events.on('routeChangeStart', triggerEvents);

        return () => {
            router.events.off('routeChangeStart', triggerEvents);
        };
    });

    return null;
};

export default InitRouter;
