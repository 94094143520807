import styled from '@emotion/styled';
import { Field, FieldProps, Form as FormikForm, Formik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, useState } from 'react';
import * as yup from 'yup';
import Stars from '../../utils/rating/stars';
import { CustomerReviewData } from '@hello-pimster/pimster-crm-sdk';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { COMMON, FORM, RATINGS } from '@constants';
import { Heading2 } from '@components/utils/text';
import { TrackEvent } from '@enums';
import { Button, FormControl, Input, Textarea, VStack } from '@lib/uikit';
import { UseFormSubmitMutate } from '@hooks';

interface Props {
    formName: string;
    formUID: string;
    isSubmitting: boolean;
    onSubmit: UseFormSubmitMutate;
}

type FormData = Omit<
    CustomerReviewData,
    'submittedAt' | 'product' | 'companyId'
>;

const Form = styled(FormikForm)`
    width: 100%;
`;

export const ReviewForm: FC<Props> = ({
    formName,
    formUID,
    isSubmitting,
    onSubmit,
}) => {
    const {
        query: { product },
    } = useRouter();
    const { track } = useAnalytics();
    const { t } = useTranslation([RATINGS, FORM, COMMON]);
    const [formHasBeenFocused, setFormHasBeenFocused] =
        useState<boolean>(false);

    const initialValues: FormData = {
        email: '',
        rating: 5,
        comment: '',
    };

    const triggerFocus = () => {
        handleFocus();
        setFormHasBeenFocused(true);
    };

    const handleFocus = () => {
        if (!formHasBeenFocused) {
            track(TrackEvent.FormStarted, {
                name: formName,
                form_uid: formUID,
            });
        }

        setFormHasBeenFocused(true);
    };

    const validationSchema = yup.object({
        email: yup
            .string()
            .email(t(`${FORM}:errors.email.format`))
            .required(t(`${FORM}:errors.required`)),
        rating: yup.number().required(t(`${FORM}:errors.required`)),
        comment: yup.string(),
    });

    return (
        <Formik
            validateOnBlur={false}
            validateOnMount={false}
            validateOnChange={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(data) =>
                onSubmit({
                    data: { ...data, product: product as string },
                    customerInputType: 'REVIEW',
                })
            }
        >
            <Form onFocus={handleFocus}>
                <VStack spacing='xs' textAlign='center'>
                    <Heading2>{t('heading')}</Heading2>
                    <Field name='rating'>
                        {({ field, form }: FieldProps) => (
                            <Stars
                                value={field.value}
                                onChange={(i) => {
                                    triggerFocus();
                                    form.setFieldValue('rating', i);
                                }}
                            />
                        )}
                    </Field>
                    <VStack spacing='xs'>
                        <Field name='comment'>
                            {({ field, form }: FieldProps) => (
                                <FormControl
                                    name='comment'
                                    errorMessage={form.errors.comment as string}
                                >
                                    <Textarea
                                        {...field}
                                        height='8rem'
                                        placeholder={`${t('your_comment')}`}
                                    />
                                </FormControl>
                            )}
                        </Field>
                        <Field name='email'>
                            {({ field, form }: FieldProps) => (
                                <FormControl
                                    name='email'
                                    errorMessage={form.errors.email as string}
                                >
                                    <Input
                                        {...field}
                                        type='email'
                                        placeholder={
                                            t(`${FORM}:labels.email`) + '*'
                                        }
                                    />
                                </FormControl>
                            )}
                        </Field>
                    </VStack>
                    <Button
                        isFullWidth
                        buttonSize='large'
                        colorScheme='black'
                        type='submit'
                        isLoading={isSubmitting}
                    >
                        {t('send')}
                    </Button>
                </VStack>
            </Form>
        </Formik>
    );
};
