import {
    MultiPagesFormComponentFragment,
    SinglePageFormComponentFragment,
} from '@graphql/generated/graphql';
import {
    isFormElement,
    isFormField,
    isFormPage,
    isFormTemplate,
} from './guards';
import { CustomFormType } from '@enums';

export const getCustomFormType = (
    form:
        | SinglePageFormComponentFragment['Form']
        | MultiPagesFormComponentFragment['Form']
) => {
    const elements = form?.Elements;

    if (!elements?.length) return;

    const formElements = elements.filter(isFormElement);

    if (!formElements.length) return;

    if (isFormField(formElements[0])) {
        if (formElements.some((e) => !isFormField(e))) {
            return;
        }

        return CustomFormType.SinglePageCustom;
    }

    if (isFormTemplate(formElements[0])) {
        if (elements.length > 1) {
            return;
        }

        return CustomFormType.SinglePageTemplate;
    }

    if (isFormPage(formElements[0])) {
        if (elements.some((e) => !isFormPage(e))) {
            return;
        }

        return CustomFormType.MultiPages;
    }
};
