import Tray from '@components/containers/tray';
import { ModalCloseReason, TrackEvent } from '@enums';
import { useAnalytics } from '@lib/analytics/analyticsContext';
import { useEffect, useState } from 'react';
import { useFormModalSubmit } from '@hooks';
import { Button, VStack } from '@lib/uikit';
import { Body, Heading2 } from '@components/utils/text';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import { COMMON } from '@constants';
import { RenderFormModalComponent } from './RenderFormModalComponent';
import { CustomFormModalProps } from '@types';

interface Props extends Omit<CustomFormModalProps, 'render'> {}

const Text = styled(Body)`
    font: ${(props) => props.theme.fonts.subtitle4};
`;

export const CustomFormModal = ({
    type,
    name,
    UID,
    defaultContent,
    elements,
    formName,
    formUID,
    isProductRegistration,
    customerInputType,
    isOpen,
    onClose,
}: Props) => {
    const { t } = useTranslation(COMMON);
    const { track } = useAnalytics();
    const [submitError, setSubmitError] = useState(false);

    const { isSubmitting, onSubmit } = useFormModalSubmit({
        modalName: name,
        modalUID: UID,
        formName,
        formUID,
        isProductRegistration,
        onSuccess: () => handleClose(ModalCloseReason.SubmitSuccess),
        onError: () => setSubmitError(true),
    });

    useEffect(() => {
        if (isOpen) {
            track(TrackEvent.ModalOpen, {
                name,
                modal_uid: UID,
                modal_type: type,
            });
        }
    }, [isOpen, name, type, UID, track]);

    const handleClose = (reason?: ModalCloseReason): void => {
        onClose();

        track(TrackEvent.ModalClose, {
            name,
            modal_uid: UID,
            modal_type: type,
            close_reason: reason || ModalCloseReason.SimpleClose,
        });

        setSubmitError(false);
    };

    return (
        <Tray isOpen={isOpen} onClose={handleClose}>
            {submitError ? (
                <VStack align='center' textAlign='center'>
                    <Heading2>{t('form_modal_error.title')}</Heading2>
                    <Text>{t('form_modal_error.description')}</Text>
                    <Button
                        isFullWidth
                        buttonSize='large'
                        colorScheme='black'
                        onClick={() => handleClose()}
                    >
                        {t('close')}
                    </Button>
                </VStack>
            ) : (
                <VStack spacing='sm'>
                    {defaultContent}
                    {elements.map((element, index) => (
                        <RenderFormModalComponent
                            key={index}
                            component={element}
                            customerInputType={customerInputType}
                            isSubmitting={isSubmitting}
                            onSubmit={onSubmit}
                            onCloseModal={handleClose}
                        />
                    ))}
                </VStack>
            )}
        </Tray>
    );
};
