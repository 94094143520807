import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ReactNode, useMemo } from 'react';
import GlobalStyles from './GlobalStyles';
import { createCompanyTheme } from './utils/createCompanyTheme';
import { CompanyTheme } from './theme/types';

type Props = {
    children: ReactNode;
    companyTheme?: CompanyTheme;
};

const ThemeProvider: React.FC<Props> = ({ children, companyTheme }) => {
    const theme = useMemo(() => {
        return createCompanyTheme(companyTheme);
    }, [companyTheme]);

    return (
        <EmotionThemeProvider
            theme={(initialTheme) => {
                return {
                    ...initialTheme,
                    ...theme,
                };
            }}
        >
            <GlobalStyles />
            {children}
        </EmotionThemeProvider>
    );
};

export default ThemeProvider;
