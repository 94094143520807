import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
} from 'react';
import { useFormikContext } from 'formik';
import { useSurveyContext } from './SurveyContext';
import { SingleSelectDisplayType } from '@graphql/generated/graphql';
import { useMutation } from '@tanstack/react-query';
import { DynamicFormSchemaType } from '@types';
import {
    getFieldKey,
    isFormField,
    isFormFieldOrConsent,
} from '@utils/form/custom';

export type FormContextProviderProps = {
    children: ReactNode;
};

type FormContextType = {
    continueSurvey: () => void | Promise<void>;
    isSubmitting: boolean;
    isContinueDisabled?: boolean;
};

const partialInitialContext: FormContextType = {
    continueSurvey: () => {},
    isSubmitting: false,
    isContinueDisabled: false,
};

export const FormContext = createContext<FormContextType>(
    partialInitialContext
);

export const FormContextProvider = ({ children }: FormContextProviderProps) => {
    const { isInfoPage, currentForm, goToNextSurveyPage } = useSurveyContext();
    const { values, errors, validateForm, setErrors } =
        useFormikContext<DynamicFormSchemaType>();

    const onClickContinue = useCallback(async () => {
        if (isInfoPage) {
            goToNextSurveyPage(values);
        } else {
            const errors = await validateForm?.(values);
            let isValid = true;

            currentForm?.forEach((field, index) => {
                if (
                    isFormFieldOrConsent(field) &&
                    errors.hasOwnProperty(getFieldKey(field, index))
                ) {
                    isValid = false;
                }
            });

            if (isValid) {
                await goToNextSurveyPage(values);
                setErrors({});
            }
        }
    }, [
        currentForm,
        isInfoPage,
        values,
        validateForm,
        goToNextSurveyPage,
        setErrors,
    ]);

    const { isPending: isSubmitting, mutateAsync: continueSurvey } =
        useMutation({
            mutationFn: onClickContinue,
        });

    const isContinueDisabled = useMemo(() => {
        const formFields = currentForm.filter(isFormField);

        if (
            formFields.length === 1 &&
            formFields[0].__typename === 'ComponentFormFieldsSingleSelect' &&
            formFields[0].DisplayType === SingleSelectDisplayType.RadioCard &&
            (errors.hasOwnProperty(formFields[0].Name) ||
                !values[formFields[0].Name])
        ) {
            return true;
        }

        return false;
    }, [currentForm, errors, values]);

    const initialContext: FormContextType = {
        ...partialInitialContext,
        isContinueDisabled,
        isSubmitting,
        continueSurvey,
    };

    return (
        <FormContext.Provider value={initialContext}>
            {children}
        </FormContext.Provider>
    );
};

FormContextProvider.displayName = 'FormContextProvider';

export const useFormContext = () => useContext(FormContext);
