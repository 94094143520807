import { useFormikContext } from 'formik';
import { Button } from '@lib/uikit';
import { FormStatus } from '@types';

type Props = {
    text: string;
};

const FormButton = ({ text }: Props) => {
    const { submitForm, isSubmitting, status } =
        useFormikContext<Record<string, unknown>>();

    return (
        <Button
            buttonSize='large'
            isFullWidth
            colorScheme='black'
            type='button'
            formMethod='POST'
            isLoading={isSubmitting}
            disabled={
                isSubmitting ||
                status === FormStatus.UPLOADING ||
                status === FormStatus.DISABLED
            }
            onClick={submitForm}
        >
            {text}
        </Button>
    );
};

export default FormButton;
