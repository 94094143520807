import { OutboundLinkContext } from '@enums';
import { RichText } from '@components/utils/RichText';
import { EmailConsent } from '@components/consents/EmailConsent';
import { RenderFormFieldComponent } from './RenderFormFieldComponent';
import { FormElementComponent } from '@types';

interface Props {
    formElementComponent: FormElementComponent;
    companyName: string;
    outboundLinkContext: OutboundLinkContext;
}

export const RenderFormElementComponent = ({
    formElementComponent,
    companyName,
    outboundLinkContext,
}: Props) => {
    switch (formElementComponent.__typename) {
        case 'ComponentFormFieldsDateInput':
        case 'ComponentFormFieldsInput':
        case 'ComponentFormFieldsFileInput':
        case 'ComponentFormFieldsSingleSelect': {
            return (
                <RenderFormFieldComponent
                    fieldComponent={formElementComponent}
                />
            );
        }

        case 'ComponentConsentsEmail':
            return (
                <EmailConsent
                    companyName={companyName}
                    element={formElementComponent}
                    outboundLinkContext={
                        outboundLinkContext ?? OutboundLinkContext.Modal
                    }
                />
            );

        case 'ComponentDisplayRichText':
            return <RichText element={formElementComponent} />;

        default:
            return null;
    }
};
