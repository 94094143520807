import { css } from '@emotion/react';
import { colors } from './colors';
import { typography } from './typography';
import { palette } from './palette';
import { components } from './components';
import { opacity } from './opacity';

export const dynamicWidth = () => css`
    @media (min-width: 748px) {
        max-width: 748px;
        @supports (aspect-ratio: 9/16) {
            width: unset;
            aspect-ratio: 9/16;
        }
    }
`;

const defaultTheme = {
    palette,
    typography,
    shadows: {
        none: 'none',
        default: '0px 0px 15px rgba(46, 46, 46, 0.1)',
        lg: '0px 0px 15px rgba(46, 46, 46, 0.2)',
        1: '0px 3px 12px 0px rgba(46, 46, 46, 0.1)',
    },
    blurs: { default: 'blur(6px)' },
    borders: {
        1: `1px solid ${colors.grey_100}`,
        2: `1px solid ${colors.grey_200}`,
    },
    gradients: {
        backdropUp:
            'linear-gradient(179.89deg, rgba(46, 46, 46, 0.5) 0.1%, rgba(46, 46, 46, 0.3) 46.4%, rgba(255, 255, 255, 0))',
        backdropDown:
            'linear-gradient(179.82deg, rgba(255, 255, 255, 0) 0.16%, rgba(46, 46, 46, 0.3) 48.47%, rgba(46, 46, 46, 0.5) 79.45%)',
        loader: 'linear-gradient(90deg, rgba(255,255,255,0.6) 0%, rgba(255,255,255,0.3) 33%, rgba(255,255,255,0) 100%)',
    },
    shape: {
        radius: {
            default: '1rem',
            xxs: '4px',
            xs: '8px',
            sm: '10px',
            lg: '22px',
            round: '9999rem',
        },
    },
    components,
    spacing: {
        xxxs: '0.125rem',
        xxs: '.25rem',
        xs: '.5rem',
        sm: '1rem',
        md: '1.5rem',
        lg: '2rem',
        xl: '2.5rem',
        xxl: '3rem',
        xxxl: '6rem',
    },
    zIndex: {
        mobileStepper: 1000,
        appBar: 1100,
        drawer: 1200,
        modal: 1300,
        snackbar: 1400,
        tooltip: 1500,
    },
    opacity,
};

export default defaultTheme;
